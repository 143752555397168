<template>
  <main class="w-full h-custom pt-10 pb-24 flex justify-center">
    <div class="w-1/2 xsm:w-5/6 sm:w-4/3">
      <h1 class="text-3xl font-bold text-center">My Projects</h1>
      <p class="my-5 text-xl">
        <te-icon name="braces" />
        My Open Sources Projects (and others)
      </p>
      <section class="grid lg:grid-cols-4 gap-4 md:grid-cols-2 sm:grid-cols-2 xsm:grid-cols-2">
        <card tag="a" href="https://www.npmjs.com/~ajomuch92" target="_blank">
          <img class="h-16" src="../assets/node-js-icon.png" />
          <span class="text-center">My NPM packages</span>
        </card>
        <card tag="a" href="https://www.npmjs.com/~ajomuch92" target="_blank">
          <img class="h-16" src="../assets/icons8-flutter-64.png" />
          <span class="text-center">My Flutter Packages</span>
        </card>
        <card tag="a" href="https://www.youtube.com/watch?v=K72ez9L-9k0&t=166s" target="_blank">
          <img class="h-16" src="../assets/youtube.png" />
          <span class="text-center">Workshop about Vuejs 3</span>
        </card>
        <card tag="a" href="https://play.google.com/store/apps/developer?id=Aar%C3%B3n+Josu%C3%A9+Montes" target="_blank">
          <img class="h-16" src="../assets/android.png" />
          <span class="text-center">My apps in Playstore</span>
        </card>
      </section>
      <hr class="my-5" />
      <p class="my-5 text-xl">
        <te-icon name="git" />
        My Contributions to other projects
      </p>
      <ul class="list-disc">
        <li>On <a class="text-own-blue" href="https://github.com/buefy/buefy/pull/3815" target="_blank">Buefy</a> project, a components library for Vuejs.</li>
        <li>On <a class="text-own-blue" href="https://github.com/Dafrok/v-hotkey/pull/46" target="_blank">v-hotkey</a> project, a directive for binding hotkeys to components.</li>
        <li>On <a class="text-own-blue" href="https://github.com/lodash/lodash/pull/5057" target="_blank">Lodash</a> project, a library with different functions for Javascript.</li>
        <li>On <a class="text-own-blue" href="https://github.com/thyagoluciano/flutter_radio/pull/53" target="_blank">Flutter Radio</a>, a plugin package for Flutter.</li>
        <li>On <a class="text-own-blue" href="https://github.com/tiamo/flutter-concentric-transition/pull/8" target="_blank">Flutter Concentric Transition</a>, a package to show an animated transition with different views.</li>
        <li>The <a class="text-own-blue" href="https://play.google.com/store/apps/details?id=com.excoino.excoino" target="_blank">Excoino app</a> uses <a class="text-own-blue" href="https://pub.dev/packages/simple_connection_checker" target="_blank">one</a> of my packages.</li>
        <li><a class="text-own-blue" href="https://pub.dev/packages/simple_connection_checker" target="_blank">My package</a> is included in <a class="text-own-blue" href="https://fluttergems.dev/network-connectivity-status/" target="_blank">Flutter Gems</a>.</li>
        <li>I published <a class="text-own-blue" href="https://rapidapi.com/ajomuch92/api/country-phone-codes/" target="_blank">my API</a> on Rapidapi platform.</li>
        <li>My Master Degree <a class="text-own-blue" href="https://lung-pneumonia-predictor.netlify.app/" target="_blank">project</a> about image recognition</li>
        <li>On project "Handy EKG: a low-cost device for the reading and analysis of the electrical activity of the heart" on <a class="text-own-blue" href="http://compdes.org/libros/compdes2021.pdf" target="_blank">Compdes 2021 [pag153]</a>.</li>
        <li>This was page was built with my own <a class="text-own-blue" href="https://vue-tailwind-elements.netlify.app/" target="_blank">library</a>.</li>
      </ul>
      <p class="mt-4 text-xl">Want to know more about my projects? Visit my <a class="text-own-blue" href="https://github.com/ajomuch92" target="_blank">GitHub profile</a>.</p>
    </div>
  </main>
</template>

<script>
import Card from '../components/Card.vue';

export default {
  name: 'Projects',
  components: { Card },
}
</script>

<style>

</style>
<template>
  <component :is="tag" class="h-32 w-full shadow-md rounded-lg flex justify-center items-center flex-col hover:scale-105 transform transition duration-50 ease-linear" :class="{'cursor-pointer': clickable}" @click="$emit('click', $event)">
    <slot name="default" />
  </component>
</template>

<script>
export default {
  name: 'Card',
  props: {
    clickable: {
      type: Boolean,
      default: false,
    },
    tag: {
      type: String,
      default: 'div'
    }
  },
}
</script>